<template>
  <the-sidebar></the-sidebar>
</template>

<script>
import TheSidebar from '@/components/TheSidebar.vue'

export default {
  components: {
    TheSidebar
  }
}
</script>

