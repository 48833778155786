<template>
  <span></span>
</template>

<script>
// This element is intentionally empty. We don't display cut in post, it will be added programmatically
export default {
}
</script>

<style lang="scss" scoped>
</style>

