<template>
  <div class="form-horizontal">
    <slot></slot>
  </div>
</template>


<script>
export default {
  methods: {
  }
}
</script>
